import React, {Component, ReactNode} from 'react';
import {IProduct} from '../../../../types/galleryTypes';
import {Dropdown, DropdownOptionProps, DropdownProps, ColorPicker} from 'wix-ui-tpa';
import {RadioButtonChangeEvent, RadioButtonClickEvent} from 'wix-ui-core';
import {IProductSelectionAvailabilityMap} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import s from './ProductOptions.scss';
import {ProductOptionType} from '@wix/wixstores-graphql-schema/dist/src/graphql-schema';
import {classes as dropdownOptionStyles} from './DropdownOption.st.css';
import {classes as colorOptionStyles} from './ColorOption.st.css';
import classNames from 'classnames';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../../gallery/galleryGlobalStrategy';
import {ProductOptionsDiplayLimit} from '../../../../constants';
import {RevealAnimation} from '../../RevealAnimation/RevealAnimation';

type IProductOption = IProduct['options'][0];

export interface ProductOptionsChangeData {
  optionType: ProductOptionType;
}

export interface IProductOptionsProps extends IGalleryGlobalProps {
  product: IProduct;
  selectionIds: number[];
  onSelectionIdsChange(
    optionSelections: IProductOptionsProps['selectionIds'],
    changeData: ProductOptionsChangeData
  ): void;
  variantsAvailability: IProductSelectionAvailabilityMap;
  isItemHovered: boolean;
}

interface OptionRenderParams {
  option: IProduct['options'][number];
  availability: IProductSelectionAvailabilityMap[string];
}

export enum DataHook {
  ProductOption = 'product-option',
  DropdownOption = 'product-options-dropdown',
  ColorOption = 'product-options-color',
  OptionWrapper = 'product-option-wrapper',
  RevealAnimationWrapper = 'product-option-reveal-animation-wrapper',
}

export class ProductOptions extends Component<IProductOptionsProps> {
  private readonly getAllVisibleDropdownOptions = (): IProductOption[] => {
    const colorOptsCount = this.getAllVisibleColorOptions().length;
    const slotsForDropdowns = ProductOptionsDiplayLimit.totalLimit - colorOptsCount;
    return this.props.product.options
      .filter(({optionType}) => optionType === ProductOptionType.DROP_DOWN)
      .slice(0, slotsForDropdowns);
  };

  private readonly getAllVisibleColorOptions = (): IProductOption[] => {
    return this.props.product.options
      .filter(({optionType}) => optionType === ProductOptionType.COLOR)
      .slice(0, ProductOptionsDiplayLimit.colorPickersLimit);
  };

  private readonly getAllVisibleOptions = (): IProductOption[] => {
    return [...this.getAllVisibleColorOptions(), ...this.getAllVisibleDropdownOptions()];
  };

  private readonly sealColorChangeHandler =
    (optionsSelections: OptionRenderParams['option']['selections']) =>
    (event: RadioButtonChangeEvent | RadioButtonClickEvent) => {
      const {selectionIds, onSelectionIdsChange} = this.props;

      const pickedSelectionId = optionsSelections.find(({value}) => value === event.value).id;

      const nextSelectionIds = [
        ...selectionIds.filter((selectionId) => !optionsSelections.find(({id}) => id === selectionId)),
        pickedSelectionId,
      ];

      onSelectionIdsChange(nextSelectionIds, {
        optionType: ProductOptionType.COLOR,
      });
    };

  private readonly sealDropdownChangeHandler =
    (optionsSelectionIds: number[]): DropdownProps['onChange'] =>
    (selected) => {
      const {selectionIds, onSelectionIdsChange} = this.props;

      const nextSelectionIds = [
        ...selectionIds.filter((selectionId) => !optionsSelectionIds.includes(selectionId)),
        parseInt(selected.id, 10),
      ];

      onSelectionIdsChange(nextSelectionIds, {
        optionType: ProductOptionType.DROP_DOWN,
      });
    };

  private readonly renderColorOption = ({option: {id, selections, title}, availability}: OptionRenderParams) => {
    const {selectionIds} = this.props;

    const colorItems = selections
      .filter((selection) => availability[selection.id].isVisible)
      .map((selection) => {
        const isDisabled = !availability[selection.id].isSelectable;
        return (
          <ColorPicker.Item
            key={selection.id}
            aria-label={selection.description}
            value={selection.value}
            disabled={isDisabled}
            isCrossedOut={isDisabled}
            tooltip={selection.description}
            checked={selectionIds.includes(selection.id)}
          />
        );
      });

    return (
      <div key={id} data-hook={DataHook.ProductOption}>
        <ColorPicker
          className={colorOptionStyles.colorOption}
          onChange={this.sealColorChangeHandler(selections)}
          data-hook={DataHook.ColorOption}
          aria-label={title}>
          {colorItems}
        </ColorPicker>
      </div>
    );
  };

  private readonly renderDropdownOption = ({option, availability}: OptionRenderParams) => {
    const {title, selections} = option;
    const options: DropdownOptionProps[] = selections
      .filter((selection) => availability[selection.id].isVisible)
      .map((selection) => ({
        id: selection.id.toString(),
        isSelectable: availability[selection.id].isSelectable,
        value: selection.description,
      }));
    return (
      <div data-hook={DataHook.ProductOption}>
        <Dropdown
          className={dropdownOptionStyles.dropdownOption}
          data-hook={DataHook.DropdownOption}
          placeholder={title}
          options={options}
          onChange={this.sealDropdownChangeHandler(selections.map((selection) => selection.id))}
        />
      </div>
    );
  };

  private readonly renderAllColorOptions = (colorOptions: IProductOption[]) => {
    const {variantsAvailability} = this.props;
    const {fixProductOptionsLayoutBugs} = this.props.globals.experiments;

    return colorOptions.map((option) => {
      return (
        <div
          key={option.id}
          className={classNames(
            s.color,
            {[s.fixLayout]: fixProductOptionsLayoutBugs},
            this.getVisibilityClassNames(option)
          )}
          data-hook={DataHook.OptionWrapper}>
          {this.renderColorOption({option, availability: variantsAvailability[option.id]})}
        </div>
      );
    });
  };

  private readonly getVisibilityClassNames = (option: IProductOption) => {
    return classNames({
      [s.lastVisibleOption]: (this.isVisible(option) || this.isFirst(option)) && !this.isNextVisible(option),
    });
  };

  public readonly renderAllDropdownOptions = (dropdownOptions: IProductOption[]) => {
    const {variantsAvailability} = this.props;
    const {fixProductOptionsLayoutBugs} = this.props.globals.experiments;
    return dropdownOptions.map((option) =>
      this.wrapAnimaiton(
        <div
          key={`${option.id}${this.isVisible(option)}`}
          className={classNames(
            s.dropdown,
            {[s.fixLayout]: fixProductOptionsLayoutBugs},
            this.getVisibilityClassNames(option)
          )}
          data-hook={DataHook.OptionWrapper}>
          {this.renderDropdownOption({option, availability: variantsAvailability[option.id]})}
        </div>,
        this.isVisible(option),
        this.isFirst(option)
      )
    );
  };

  private wrapAnimaiton(child: ReactNode, isVisible: boolean, preserveSpace: boolean): ReactNode {
    return this.props.globals.isOptionsRevealEnabled ? (
      <RevealAnimation isVisible={isVisible} preserveSpace={preserveSpace} data-hook={DataHook.RevealAnimationWrapper}>
        {child}
      </RevealAnimation>
    ) : (
      child
    );
  }

  private isOptionSelected(option: IProductOption): boolean {
    return option.selections.find(({id}) => this.props.selectionIds.includes(id)) !== undefined;
  }

  private isFirst(option: IProductOption): boolean {
    const options = this.getAllVisibleOptions();
    return options.length && options[0].id === option.id;
  }

  private isVisible(option: IProductOption): boolean {
    const options = this.getAllVisibleOptions();
    const index = options.findIndex(({id}) => option.id === id);
    return (
      this.isOptionSelected(option) ||
      (this.isFirst(option) && this.props.isItemHovered) ||
      (options[index - 1] && this.isOptionSelected(options[index - 1]))
    );
  }

  private isNextVisible(option: IProductOption): boolean {
    const options = this.getAllVisibleOptions();
    const index = options.findIndex(({id}) => option.id === id);
    return options[index + 1] && this.isVisible(options[index + 1]);
  }

  public render() {
    const colorOptions = this.getAllVisibleColorOptions();
    const dropdownOptions = this.getAllVisibleDropdownOptions();
    return (
      <>
        {this.renderAllColorOptions(colorOptions)}
        {this.renderAllDropdownOptions(dropdownOptions)}
      </>
    );
  }
}

export const ProductOptionsWithGlobals = withGlobals(ProductOptions);
